<template>
  <v-container fluid fill-height class="down-top-padding">
    <v-row class="align-self-start">
      <v-col cols="12" lg="5">
        <BaseCard heading="Mis datos personales">
            <div class="mt-0">
                <v-card-text class="text-center pa-0">
                    <img
                    src="../../assets/users/6.jpeg"
                    alt="user"
                    width="150px"
                    class="img-fluid rounded-circle shadow-sm"
                    />
                    <!-- <h4 class="mt-2 title blue-grey--text text--darken-2 font-weight-regular">Hanna Gover</h4>
                    <h6 class="subtitle-2 font-weight-light">Accoubts Manager Amix corp</h6> -->
                </v-card-text>
                <v-card-text>
                    <v-text-field
                        v-model="usrName"
                        label="Nombre y Apellido"
                        filled
                        background-color="transparent"
                    ></v-text-field>
                    <v-text-field
                        type="email"
                        v-model="usrEmail"
                        label="Email"
                        filled
                        background-color="transparent"
                    ></v-text-field>
                    <v-text-field
                        v-model="linkrefer"
                        label="Enlace de Referencia"
                        filled
                        background-color="transparent"
                    >
                        <template v-slot:append>
                            <v-btn
                                class="mb-1"
                                fab
                                dark
                                small
                                color="success"
                                title="Copiar enlace"
                                @click="copy(linkrefer)"
                            >
                            <v-icon dark>mdi-content-copy</v-icon>
                            </v-btn>
                        </template>
                    </v-text-field>
                    <v-text-field
                        v-model="usrPassword"
                        filled
                        background-color="transparent"
                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[rules.required, rules.min]"
                        :type="show1 ? 'text' : 'password'"
                        name="input-10-1"
                        label="Password"
                        hint="At least 8 characters"
                        counter
                        @click:append="show1 = !show1"
                    ></v-text-field>
                    <v-text-field
                        v-model="password2"
                        filled
                        background-color="transparent"
                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[rules.required, rules.min]"
                        :type="show1 ? 'text' : 'password'"
                        name="input-10-2"
                        label="Confirmar Password"
                        hint="At least 8 characters"
                        counter
                        @click:append="show1 = !show1"
                    ></v-text-field>
                    <div>
                        <v-alert
                        text
                        dense
                        type="success"
                        border="left"
                        class="py-5"
                        v-if="viewSuccess"
                        ><b>!Atención!</b> {{msgSuccess}}.</v-alert>
                        <v-alert
                            text
                            dense
                            type="error"
                            border="left"
                            class="py-5"
                            v-if="viewError"
                        ><b>!Atención!</b> {{msgError}}.</v-alert>
                    </div>
                    <!-- <button type="button" class="btn btn-rounded btn-sm btn-primary w-100 subtitle-1 font-weight-medium" @click="saveUser"> Guardar </button> -->
                </v-card-text>
            </div>
        </BaseCard>
      </v-col>
      <v-col cols="12" lg="7">
        <v-row>
            <v-col>
                <BaseCard heading="Wallets de Retiro">
                    <div class="mt-0">
                        <v-card-text class="text-center pa-0">
                            <v-select
                            v-model="tpWallet"
                            :items="tpWallets"
                            label="Tipo de Wallet"
                            ></v-select>
                            <v-text-field
                                label="Codigo de Wallet"
                                filled
                                background-color="transparent" 
                                v-model="walletUser"   
                            >

                            <!-- <template v-slot:append>
                                <v-btn
                                    class="mb-1"
                                    fab
                                    dark
                                    small
                                    color="success"
                                    title="Agregar Wallet"
                                >
                                <v-icon dark>mdi-wallet-plus</v-icon>
                                </v-btn>
                            </template> -->

                            </v-text-field>
                        </v-card-text>
                        <!-- <v-data-table
                            :headers="headers"
                            :items="desserts"
                            sort-by="calories"
                            hide-default-header
                            hide-default-footer
                            class="elevation-1"
                        >
                            <template v-slot:top>
                                <v-toolbar
                                    flat
                                >
                                    <v-toolbar-title>Wallets Registradas</v-toolbar-title>
                                </v-toolbar>
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                            <v-icon
                                small
                                class="mr-2"
                                @click="editItem(item)"
                            >
                                mdi-pencil
                            </v-icon>
                            <v-icon
                                small
                                @click="deleteItem(item)"
                            >
                                mdi-delete
                            </v-icon>
                            </template>
                            <template v-slot:no-data>
                            <v-btn
                                color="primary"
                                @click="initialize"
                            >
                                Reset
                            </v-btn>
                            </template>
                        </v-data-table> -->
                    </div>
                </BaseCard>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <BaseCard heading="Mis planes contratados" class="marginPlanes">
                    <div class="mt-0">
                        <v-data-table
                            dense
                            fixed-header
                            height="260px"
                            :headers="headersPlan"
                            :items="misPlanes"
                            :single-expand="singleExpand"
                            :expanded.sync="expanded"
                            item-key="id_plan"
                            show-expand
                            sort-by="fecha"
                            :sort-desc="true"
                            :items-per-page="10"
                            :footer-props="{
                            showFirstLastPage: true,
                            firstIcon: 'mdi-page-first',
                            lastIcon: 'mdi-page-last',
                            prevIcon: 'mdi-chevron-left',
                            nextIcon: 'mdi-chevron-right'
                            }"
                            :loading="loading"
                            loading-text="Cargando... Un momento por favor"
                        >
                        <template v-slot:expanded-item="{ headers, item }">
                            <td :colspan="headers.length">
                            <v-row>
                                <v-col cols="12" lg="5">
                                    <div class="pt-3">Inversión mínima: <b>{{item.va_inversion_min}}</b></div>
                                    <div class="pt-1 pb-2">Inversión máxima: <b>{{item.va_inversion_max}}</b></div>
                                </v-col>
                                <v-col cols="12" lg="7">
                                    <div class="pt-3">Rendimiento: {{item.va_diario}} x día</div>
                                    <div class="pt-1 pb-2">Cada <b>{{item.va_horas}} horas</b> beneficio: <b>{{item.va_horas_profit}}</b></div>
                                </v-col>
                            </v-row>
                            </td>
                        </template>
                        </v-data-table>
                    </div>
                </BaseCard>
            </v-col>
        </v-row>
      </v-col>
      <v-col style="text-align:center">
        <button type="button" class="btn btn-rounded btn-sm btn-primary w-50 subtitle-1 font-weight-medium" style="min-width: 250px;" @click="saveUser"> Guardar </button>
      </v-col>
    </v-row>
    <sesionExpiredDialog
      :dialog.sync="dialogSesion"
      persistent
      transition="dialog-bottom-transition"
      max-width="320"
    >
    </sesionExpiredDialog>
  </v-container>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import axios from 'axios'
import moment from 'moment'
import VsToast from '@vuesimple/vs-toast'
import sesionExpiredDialog from '@/components/mainComponents/SessionExpiredDialog.vue'

export default {
  name: "Profile",

  data: () => ({
    usrToken: '',
    usrData: {},
    usrName: '',
    usrEmail: '',
    usrPassword: "",
    password2: "",
    tpWallet: '',
    walletUser: '',
    misPlanes: [],
    expanded: [],
    singleExpand: false,
    headersPlan: [
        { text: 'Nomb. plan', value: 'nb_plan' },
        { text: 'Fecha compra', value: 'fecha', width: 140 },
        { text: 'Valor', value: 'va_monto' },
        { text: 'Beneficio', value: 'va_beneficio'},
        { text: 'Plazo inversión', value: 'va_plazo' },
        { text: 'Plazo restante', value: 'plazo_restante'},
        { text: '', value: 'data-table-expand'},
        { text: 'ID plan', value: 'id_plan', visible: false },
    ],
    viewSuccess: false,
    msgSuccess: '',
    viewError: false,
    msgError: '',
    show1: false,
    linkrefer: '',
    rules: {
      required: value => !!value || "Required.",
      min: v => v.length >= 8 || "Min 8 characters",
      emailMatch: () => "The email and password you entered don't match",
      //passConfirm: value => value === this.password || "Passwords son diferentes" 
    },
    tpWallets: ['USDT TRC20'],
    dialog: false,
    dialogSesion: false,
    dialogDelete: false,
    loading: false,
    headers: [
        { text: 'Tipo Wallet', value: 'protein' },
        { text: 'Cod. Wallet', value: 'name' },
        { text: 'Actions', value: 'actions', sortable: false },
    ],
      desserts: [],
      editedIndex: -1,
      editedItem: {
        name: '',
        calories: 0,
        fat: 0,
        carbs: 0,
        protein: 0,
      },
      defaultItem: {
        name: '',
        calories: 0,
        fat: 0,
        carbs: 0,
        protein: 0,
      },
  }),
  components: {
    sesionExpiredDialog
  },
  computed: {
      ...mapState(["usrWallet"]),
      formTitle () {
        return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    async created() {
        this.usrData = await JSON.parse(localStorage.getItem("user"))
        this.usrToken = localStorage.getItem("usrToken")
        this.initialize()
    },

    methods: {
        ...mapMutations({
            set_usrWallet: "set_usrWallet",
        }),
        initialize() {
            this.usrName = this.usrData.nombre
            this.usrEmail = this.usrData.email
            this.linkrefer = process.env.VUE_APP_WEB_URL + '/?refer=' + this.usrData.co_user
            this.tpWallet = (this.usrData.wallet && this.usrData.wallet.nb_wallet) ? this.usrData.wallet.nb_wallet : ''
            this.walletUser = (this.usrData.wallet && this.usrData.wallet.wallet) ? this.usrData.wallet.wallet : ''
            this.getPlanes()
            
        },
        async saveUser() {
            this.viewError= false
            this.viewSuccess= false
            let bodyFormData = new FormData();
			console.log('Valores: ', !this.usrPassword);
			if(this.usrName === ""){
				VsToast.show({
					title: 'Registro',
					message: 'El Nombre es requerido',
					variant: 'warning',
                    timeout: 3000
				});
                return
			}
            if(this.usrEmail === ""){
				VsToast.show({
					title: 'Registro',
					message: 'El Email es requerido',
					variant: 'warning',
                    timeout: 3000
				});
                return
			} 
            if(this.usrPassword != this.password2){
				VsToast.show({
					title: 'Registro',
					message: 'Debe confirmar el Password ingresado',
					variant: 'warning',
                    timeout: 3000
				});
                return
			} 
            bodyFormData.append('name', this.usrName);
            bodyFormData.append('email', this.usrEmail);
            if(this.usrPassword) bodyFormData.append('password', this.usrPassword);
            let respSaveUser= await axios({
                method: 'PUT',
                url: `${process.env.VUE_APP_API_URL}/api/users/${this.usrData.id}`,
                data: bodyFormData,
                headers: { 
                    "Authorization": process.env.VUE_APP_TOKEN_TYPE + ' ' + this.usrToken,
                    //"Accept": "application/json"
                }
            })
            .then(async response => {
                //this.loading = false
                let respUser = response.data
                console.log('respUser...', respUser);
                if (!respUser.success){
                    VsToast.show({
                        title: 'Perfil de Usuario',
                        message: 'No se han podido guardar los datos del usuario.',
                        variant: 'error',
                        timeout: 2000,
                        type: "alert"
                    });
                    this.msgError = respUser.data.msg
                    this.viewError = true
                    return {success: false, data: this.msgError}
                }
                else {
                    console.log('respUser Succes: ', respUser)
                    VsToast.show({
                        title: 'Perfil de Usuario',
                        message: 'Perfil de Usuario guardado exitosamente.',
                        variant: 'success',
                        timeout: 2000,
                        type: "alert"
                    });
                }
                this.viewSuccess= true
                this.msgSuccess= respUser.data
                this.usrPassword= ''
                this.password2= ''
                return {success: true, data: response.data}
            })
            .catch(error => {
                VsToast.show({
                    title: 'Perfil de Usuario',
                    message: 'Los datos del Perfil de Usuario no puede ser procesados.',
                    variant: 'error',
                    timeout: 2000,
                    type: "alert"
                });
                console.error('respUser Error: ', error)
                this.loading = false;
                //let message = !error.response ? error.message : error.response.data.msg;
                this.msgError = (error.response && error.response.data) ? error.response.data.msg : error.message
                this.viewError = true
                if(error.response && error.response.statusText == "Unauthorized"){
                    this.dialogSesion = true
                }
                return {success: false, data: this.msgError}
            })

            if(respSaveUser){
                await axios({
                    method: 'POST',
                    url: `${process.env.VUE_APP_API_URL}/api/users/setwallets`,
                    params: {
                        wallet: this.walletUser,
                        tipo_wallet_id:1
                    },
                    headers: { 
                        "Authorization": process.env.VUE_APP_TOKEN_TYPE + ' ' + this.usrToken,
                        //"Accept": "application/json"
                    }
                })
                .then(async response => {
                    //this.loading = false
                    let respWallet = response.data
                    console.log('respWallet...', respWallet);
                    if (!respWallet.success){
                        VsToast.show({
                            title: 'Perfil de Usuario',
                            message: 'No se han podido guardar los datos de la wallet del usuario.',
                            variant: 'error',
                            timeout: 2000,
                            type: "alert"
                        });
                        this.msgError = respWallet.data.msg
                        this.viewError = true
                        return {success: false, data: respWallet.data}
                    }
                    else {
                        console.log('respWallet Succes: ', respWallet)
                        VsToast.show({
                            title: 'Perfil de Usuario',
                            message: 'Perfil de Usuario guardado exitosamente.',
                            variant: 'success',
                            timeout: 2000,
                            type: "alert"
                        });
                    }
                    //this.viewSuccess= true
                    //this.msgSuccess= respWallet.data
                    this.usrPassword= ''
                    this.password2= ''
                    //console.log(this.tpWallet, this.walletUser)
                    this.usrData.wallet = {}
                    this.usrData.wallet.nb_wallet = this.tpWallet
                    this.usrData.wallet.wallet = this.walletUser
                    this.set_usrWallet(this.walletUser)
                    localStorage.setItem('user', JSON.stringify(this.usrData))
                    return {success: true, data: respWallet.data}
                })
                .catch(error => {
                    VsToast.show({
                        title: 'Perfil de Usuario',
                        message: 'Los datos del Perfil de Usuario no puede ser procesados.',
                        variant: 'error',
                        timeout: 2000,
                        type: "alert"
                    });
                    console.error('respWallet Error: ', error)
                    this.loading = false;
                    //let message = !error.response ? error.message : error.response.data.msg;
                    this.msgError = (error.response && error.response.data) ? error.response.data.msg : error.message
                    this.viewError = true
                    if(error.response && error.response.statusText == "Unauthorized"){
                        this.dialogSesion = true
                    }
                    return {success: false, data: this.msgError}
                })
            }

        },

        getPlanes() {
            axios({
                method: 'GET',
                url: `${process.env.VUE_APP_API_URL}/api/users/${this.usrData.id}/planes`,
                headers: { 
                    "Authorization": process.env.VUE_APP_TOKEN_TYPE + ' ' + this.usrToken,
                    //"Accept": "application/json"
                }
            })
            .then(async response => {
                //this.loading = false
                let respPlanes = response.data
                console.log('respPlanes...', respPlanes);
                if (!respPlanes.success){
                    this.msgError = respPlanes.data.msg
                    this.viewError = true
                }
                else {
                    this.misPlanes = respPlanes.data.map((plan) => {
                        if(plan.pivot.plazo_restante > 0)
                            return {
                                id_plan: plan.pivot.id,
                                nb_plan: plan.nb_plan,
                                fecha: moment(plan.created_at).format('YYYY-MM-DD HH:mm'),
                                va_monto: plan.pivot.va_monto.toLocaleString("es-VE"),
                                va_beneficio: plan.va_beneficio.toLocaleString("es-VE") + '%',
                                va_horas: plan.va_horas,
                                va_diario: plan.va_diario.toLocaleString("es-VE") + '%',
                                va_horas_profit: plan.va_horas_profit.toLocaleString("es-VE") + '%',
                                va_inversion_max: plan.va_inversion_max.toLocaleString("es-VE"),
                                va_inversion_min: plan.va_inversion_min.toLocaleString("es-VE"),
                                plazo_restante: plan.pivot.plazo_restante + ( plan.pivot.plazo_restante === 1 ? ' dia' : ' dias'),
                                va_plazo: plan.va_plazo + ( plan.va_plazo === 1 ? ' dia' : ' dias')
                            }
                    })
                    //.Transacciones.toLocaleString("es-VE")
                }
                
                this.loading = false
            })
            .catch(error => {
                console.error('respPlanes Error: ', error)
                this.loading = false;
                //let message = !error.response ? error.message : error.response.data.msg;
                if(error.response && error.response.statusText == "Unauthorized"){
                    this.dialogSesion = true
                }
            })

        },
        
        async copy(mytext) {
            var input = document.createElement('input');
            input.setAttribute('value', mytext);
            input.value = mytext;        
            document.body.appendChild(input);
            try {
                input.select();    
                input.click();     
                input.focus();
                var successful = document.execCommand('copy');
                var msg = successful ? 'successful' : 'unsuccessful';
                if(successful){
                    VsToast.show({
                        //title: this.titleRechazar,
                        message: 'Texto copiado...',
                        variant: 'success',
                        timeout: 2000,
                        type: "alert"
                    });
                }
                console.log('Testing code was copied ' + successful + ' ' + msg);
                
            } catch (err) {
                console.log('Oops, unable to copy');
            }
            document.body.removeChild(input);          
        },

      editItem (item) {
        this.editedIndex = this.desserts.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.desserts.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        this.desserts.splice(this.editedIndex, 1)
        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        if (this.editedIndex > -1) {
          Object.assign(this.desserts[this.editedIndex], this.editedItem)
        } else {
          this.desserts.push(this.editedItem)
        }
        this.close()
      },
    },
};
</script>

<style>

.btn-rounded {
    border-radius: 60px;
    padding: 7px 18px;
}

.btn-primary, .btn-info, .btn-default, .btn-warning, .btn-success, .btn-danger, .btn-outline-primary:hover, .btn-check:checked+.btn-outline-primary, .btn-check:checked+.btn-outline-warning, .btn-check:checked+.btn-outline-success, .btn-check:checked+.btn-outline-danger, .btn-outline-warning:hover, .btn-outline-danger:hover, .btn-outline-info:hover, .btn-outline-success:hover, .btn-primary.active, .btn-info.active, .btn-info:focus, .btn-info:disabled, .btn-success:disabled, .btn-primary:disabled, .btn-danger.active, .btn-success.active, .btn-success:focus, .btn-danger:focus, .btn-primary:focus, .show>.btn-danger.dropdown-toggle, .show>.btn-primary.dropdown-toggle, .show>.btn-info.dropdown-toggle, .show>.btn-success.dropdown-toggle, .show>.btn-warning.dropdown-toggle {
    color: #fff;
}

.btn-success:hover{
    background-color: #e8fdeb;
    border-color: rgba(6,215,156, 1);
    color:rgba(6,215,156, 1)!important
}

.saldo{
    background-color: #e8fdeb;
    border-color: #e8fdeb;
    color:rgba(6,215,156, 1)!important

}
.marginPlanes{
    margin-top: -40px !important;
}
.v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
    background: #00ccc00f;      
    -webkit-box-shadow: inset 0px 4px 8px -5px #00ccc04f, inset 0px -4px 8px -5px #00ccc04f;
    box-shadow: inset 0px 4px 8px -5px #00ccc04f, inset 0px -4px 8px -5px #00ccc04f;
}
</style>